import React, { FC } from "react"
import { graphql, useStaticQuery } from "gatsby"

import Error404Page, {
  IUmbracoError404Data,
} from "gatsby-theme-nurofen/src/templates/Error404Page"

const ErrorPage:FC = () => {
  const data: IUmbracoError404Data = useStaticQuery(graphql`
    query {
      umbracoError404(lang: { eq: "fr-be" }) {
        ...FragmentError404Page
      }
      header(lang: { eq: "fr-be" }) {
        ...FragmentHeader
      }
      footer(lang: { eq: "fr-be" }) {
        ...FragmentFooter
      }
      siteSettings(lang: { eq: "fr-be" }) {
        ...FragmentSiteSettings
      }
      covidBanner(lang: { eq: "fr-be" }) {
        type
        position
        interval
        htmlContent
        closeButtonText
        ariaLabel
      }
      newsletterPopupDefault(lang: { eq: "fr-be" }) {
        ...FragmentNewsletterPopup
      }
      newsletterPopupDiscount(lang: { eq: "fr-be" }) {
        ...FragmentNewsletterPopup
      }
      newsletterPopupFreeDelivery(lang: { eq: "fr-be" }) {
        ...FragmentNewsletterPopup
      }
      brandSettings(lang: { eq: "fr-be" }) {
        brandLogo {
          fallbackUrl
        }
        socialNetworks {
          value
        }
        brandName
      }
      disclaimerPopup(lang: { eq: "fr-be" }) {
        label
        popupTitle
        popupText
        popupCloseButtonText
        popupCloseButtonAriaLabel
      }
      searchPath: allSearchResults(filter: { lang: { eq: "fr-be" } }) {
        nodes {
          link
        }
      }
      pageLangs: allUmbracoError404(filter: { link: { regex: "/404/i" } }) {
        nodes {
          link
          lang
        }
      }
    }
  `);
  return <Error404Page data={data}/>
};

export default ErrorPage
